//In src/components/sub-form.component.js
import React, { useState } from 'react';
import styles from './SubscriptionForm.module.scss';

const SubscriptionForm = () => {
    const [status, setStatus] = useState(null);
    const [email, setEmail] = useState('');

    //FORM_URL should be the same as the form action url pointed out above
    const FORM_URL = `https://app.convertkit.com/forms/2048537/subscriptions`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        try {
            const response = await fetch(
                FORM_URL,
                {
                    method: 'post',
                    body: data,
                    headers: {
                        accept: 'application/json',
                    },
                }
            );
            setEmail('');
            const json = await response.json();
            if (json.status === 'success') {
                setStatus('SUCCESS');
                return;
            }
        } catch (err) {
            setStatus('ERROR');
            console.log(err);
        }
    };

    const handleInputChange = event => {
        const {value} = event.target;
        setEmail(value);
    }

    return (
        <div className={styles["sub__section"]} >
            <h2>Join My Newsletter📬</h2>
            <p>
                {/* Biweekly email newsletter with my latest articles and additional details about my launches, projects and experiments. */}
               If you've found any of my articles useful, subscribe to receive more quality articles straight to your inbox.
            </p>

            {status === 'SUCCESS' && <p className={styles["sub__success"]}>Please check your 📧inbox to confirm your subscription✨!</p>}
            {status === 'ERROR' && <p className={styles["sub__fail"]}>Oops, Something went wrong😟! try again.</p>}

            <form className="sub__form"
                action={FORM_URL}
                method="post"
                onSubmit={handleSubmit} >
                <input
                    type="email"
                    aria-label="Your email"
                   //The name attribute should be the same as on you selected form.
                    name="email_address"
                    placeholder="Your email address"
                    onChange={handleInputChange}
                    value={email}
                    required
                    className={styles["sub__emailinput"]}
                />
                <input className={styles['sub__btn']} type="submit" value="Subscribe" />
            </form>
            <p className={styles["sub__tag"]}>No spam, sales, or ads. Unsubscribe as your heart desires.</p>

        </div>
    );
};

export default SubscriptionForm;